import React, { useRef } from "react"
import { graphql } from "gatsby"
import HeroHeader from "../components/indonesian/home/HeroHeaderId"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import HomeContent from "../components/indonesian/home/HomeContentId"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"

const IndexPageId = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  const scrollRef = useRef(null)

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Beranda"
        seoKeywords="Perusahaan EPC di Indonesia, Perusahaan EPC terbaik di Indonesia, Perusahaan EPC Jepang di Indonesia, Kualitas Jepang, Perusahaan EPC, Perusahaan Global, Perusahaan EPC, Lokasi JFE, EPC Jepang, Perusahaan Global, Karyawan JFE Engineering Indonesia, Karyawan JFEEI, JFE Engineering Indonesia, JFEEI, PT. JFEEI, Kegiatan JFEEI, Donor Darah, Kegiatan JFEEI, Employee Gathering, Kegiatan JFEEI, Ramadhan, Kegiatan JFEEI, Olah Raga, Kegiatan JFEEI, Acara Sosial, Kebijakan Pribadi JFE Engineering Indonesia, Kebijakan Pribadi JFEEI"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <HeroHeader scrollRef={scrollRef} />
        <HomeContent scrollRef={scrollRef} />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default IndexPageId

export const query = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
