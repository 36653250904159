import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import { easePolyOut } from "d3-ease"
import { useSpring, animated } from "react-spring"
import VisibilitySensor from "react-visibility-sensor"
import SvgMap from "../../SvgMap"
import JFEEINews from "../../../multimedia/JFEEI-News.svg"
import JFEENews from "../../../multimedia/JFEE-News.svg"
import Slider from "react-slick"
import CircleBg from "../../../../static/svg/Large-Circle.svg"
import {
  Heading24,
  Heading32,
  Heading40,
  Paragraph16,
  Paragraph18,
  StyledButton,
} from "../../../styles/PageStyles"
import useWindowSize from "../../WindowDimensions"
import { RiExternalLinkLine } from "react-icons/ri"

const HomeContentId = ({ scrollRef }) => {
  const [visibility, setVisibility] = useState(false)

  const { width } = useWindowSize()

  function carouselItemWidth() {
    if (width >= 1024 && width <= 1366) {
      return 600
    } else if (width < 1024 || width > 1366) {
      return 888
    } else {
      return 888
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "250px",
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1099,
        settings: {
          dots: true,
          infinite: true,
          focusOnSelect: false,
          centerMode: false,
          centerPadding: 0,
          variableWidth: false,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "home-id" } }) {
        frontmatter {
          projectsHeading
          offices {
            hidden
            count
            title
            asOfDate
            officesImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          employees {
            hidden
            count
            title
            asOfDate
            employeesImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          jfeei_news {
            title
            subtitle
          }
          jfehq_news {
            title
            subtitle
          }
        }
      }
      projectHighlights: markdownRemark(
        frontmatter: { templateKey: { eq: "projects-id" } }
      ) {
        frontmatter {
          projects {
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              highlight {
                show
                title
              }
            }
          }
        }
      }
      newsId: allMarkdownRemark(
        filter: {
          frontmatter: { recent: { eq: true }, origin: { eq: "indonesia-id" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              origin
              title
              date(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
      newsHq: allMarkdownRemark(
        filter: {
          frontmatter: { recent: { eq: true }, origin: { eq: "hq-id" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              origin
              title
              link
              date(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
      events: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(id)/(events)/" } }
        sort: { fields: frontmatter___sort_id, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnailImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 500
                    aspectRatio: 1.5
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { projects } = data.projectHighlights.frontmatter
  const newsId = data.newsId.edges
  const newsHq = data.newsHq.edges
  const events = data.events.edges

  function useAnimatedNumbers({ number }) {
    const animatedStyle = useSpring({
      val: number,
      from: { val: 0 },
      config: { duration: 2000, easing: easePolyOut },
    })

    return {
      style: animatedStyle,
    }
  }

  function AnimatedNumber(number) {
    const animationProps = useAnimatedNumbers(number)
    return (
      <>
        <animated.h1 {...animationProps} data-aos="fade-up">
          {animationProps.style.val.to((number) =>
            Math.floor(number).toLocaleString()
          )}
        </animated.h1>
      </>
    )
  }

  function onChangeVisibility(isVisible) {
    setVisibility(isVisible)
  }

  return (
    <HomeContainer fluid>
      {projects !== null && (
        <StyledRow ref={scrollRef} id="project-highlights">
          <Heading40
            data-aos="fade-up"
            data-aos-delay={1000}
            data-aos-offset={-100}
          >
            {frontmatter.projectsHeading}
          </Heading40>
          <StyledSlider {...settings}>
            {projects?.map(
              (project, index) =>
                project?.images !== null &&
                project?.images.map(
                  (item, subIndex) =>
                    item?.highlight?.show && (
                      <div
                        key={index}
                        className="item-container"
                        style={{ width: carouselItemWidth() }}
                      >
                        <CarouselItem key={subIndex}>
                          {item?.image !== null ? (
                            <GatsbyImage
                              image={getImage(item?.image)}
                              alt={item?.highlight?.title}
                              className="carousel-img"
                              draggable={false}
                            />
                          ) : (
                            <StaticImage
                              src="../../../multimedia/placeholder-image.png"
                              alt="Placeholder Image"
                              className="carousel-img"
                              draggable={false}
                            />
                          )}

                          <div className="carousel-item-title">
                            <div className="heading">
                              <Paragraph18 className="project-title">
                                {item?.highlight?.title !== null &&
                                  item?.highlight?.title}
                              </Paragraph18>
                            </div>
                          </div>
                        </CarouselItem>
                      </div>
                    )
                )
            )}
          </StyledSlider>
        </StyledRow>
      )}
      <StyledRow>
        <Heading40>Jangkauan Kami Di Seluruh Dunia</Heading40>
        <Col xs={12} className="jfeei-offices-map">
          <SvgMap />
        </Col>
      </StyledRow>
      <StyledRow className="with-padding centered">
        {!frontmatter.offices?.hidden && (
          <StyledCol lg={6} md={12}>
            <div className="heading">
              <VisibilitySensor
                partialVisibility
                onChange={onChangeVisibility}
                active={!visibility}
              >
                <AnimatedNumber
                  number={
                    frontmatter.offices?.count !== null
                      ? frontmatter.offices?.count
                      : 0
                  }
                />
              </VisibilitySensor>
            </div>
            <div className="body">
              <Heading24 className="description">
                {frontmatter.offices?.title}
              </Heading24>
              {frontmatter.offices?.officesImage !== null ? (
                <GatsbyImage
                  image={getImage(frontmatter.offices?.officesImage)}
                  alt="Kantor Perwakilan dan Anak Perusahaan di Luar Negeri"
                />
              ) : (
                <StaticImage
                  src="../../../multimedia/placeholder-image.png"
                  alt="Placeholder Image"
                />
              )}

              <Paragraph16 className="date">
                {frontmatter.offices?.asOfDate}
              </Paragraph16>
            </div>
          </StyledCol>
        )}
        {!frontmatter.employees.hidden && (
          <StyledCol lg={6} md={12}>
            <div className="heading">
              <VisibilitySensor
                partialVisibility
                onChange={onChangeVisibility}
                active={!visibility}
              >
                <AnimatedNumber
                  visibility={visibility}
                  number={
                    frontmatter.employees?.count !== null
                      ? frontmatter.employees?.count
                      : 0
                  }
                />
              </VisibilitySensor>
            </div>
            <div className="body">
              <Heading24 className="description">
                {frontmatter.employees?.title}
              </Heading24>
              {frontmatter.employees?.employeesImage !== null ? (
                <GatsbyImage
                  image={getImage(frontmatter.employees?.employeesImage)}
                  alt="Jumlah Karyawan"
                />
              ) : (
                <StaticImage
                  src="../../../multimedia/placeholder-image.png"
                  alt="Placeholder Image"
                />
              )}
              <Paragraph16 className="date">
                {frontmatter.employees?.asOfDate}
              </Paragraph16>
            </div>
          </StyledCol>
        )}
      </StyledRow>
      <StyledRow className="news-events-section with-padding">
        <Col className="col-recent-news" lg={6} md={12}>
          <Heading32>Berita</Heading32>
          <div className="recent-news">
            {newsId.map((news, index) => (
              <Row key={index}>
                <div className={`${news?.node?.frontmatter?.origin} news`}>
                  <Col className="news-date" lg={2} md={2}>
                    <Paragraph16 className="date">
                      {news?.node?.frontmatter?.date}
                    </Paragraph16>
                  </Col>
                  <Col className="news-title" lg={8}>
                    <Link to={news?.node?.fields?.slug}>
                      <Paragraph16 className="title">
                        {news?.node?.frontmatter?.title}
                      </Paragraph16>
                    </Link>
                  </Col>
                </div>
              </Row>
            ))}
            {newsHq.map((news, index) => (
              <Row key={index}>
                <div className={`${news?.node?.frontmatter?.origin} news`}>
                  <Col className="news-date" lg={2} md={2}>
                    <Paragraph16 className="date">
                      {news?.node?.frontmatter?.date}
                    </Paragraph16>
                  </Col>
                  <Col className="news-title" lg={8}>
                    <a
                      href={news?.node?.frontmatter?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Paragraph16 className="title">
                        {news?.node?.frontmatter?.title}
                      </Paragraph16>
                    </a>
                  </Col>
                </div>
              </Row>
            ))}
          </div>
          <div className="jfeei news-link">
            <JFEEINews />
            <Link to="/id/news/jfe-engineering-indonesia/">
              <Heading24>{frontmatter.jfeei_news.title}</Heading24>
              <Paragraph16>{frontmatter.jfeei_news.subtitle}</Paragraph16>
            </Link>
          </div>
          <div className="jfee news-link">
            <JFEENews />
            <a
              href="https://www.jfe-eng.co.jp/en/news/"
              target="_blank"
              rel="noreferrer"
            >
              <Heading24>
                {frontmatter.jfehq_news.title}&nbsp;
                <RiExternalLinkLine />
              </Heading24>
              <Paragraph16>{frontmatter.jfehq_news.subtitle}</Paragraph16>
            </a>
          </div>
        </Col>
        <Col className="col-events" lg={6} md={12}>
          <Heading32>Kegiatan</Heading32>
          <EventImages>
            <div className="event-images-container">
              {events.map((event, index) => (
                <Link key={index} to={event.node?.fields?.slug}>
                  <EventFrame className={`featured-event-${index + 1}`}>
                    <EventTitle>
                      <Heading24>{event.node?.frontmatter?.title}</Heading24>
                    </EventTitle>
                    {event.node?.frontmatter?.thumbnailImage !== null ? (
                      <GatsbyImage
                        image={getImage(
                          event.node?.frontmatter?.thumbnailImage
                        )}
                        alt={event.node?.frontmatter?.title}
                      />
                    ) : (
                      <StaticImage
                        src="../../../multimedia/placeholder-image.png"
                        alt="Placeholder Image"
                      />
                    )}
                  </EventFrame>
                </Link>
              ))}
            </div>
          </EventImages>
          <StyledSlider {...settings} className="mobile-events-slider">
            {events.map((event, index) => (
              <Link key={index} to={event.node?.fields?.slug}>
                <MobileEventFrame>
                  <Heading24>{event.node?.frontmatter?.title}</Heading24>
                  {event.node?.frontmatter?.thumbnailImage !== null ? (
                    <GatsbyImage
                      image={getImage(event.node?.frontmatter?.thumbnailImage)}
                      alt={event.node?.frontmatter?.title}
                    />
                  ) : (
                    <StaticImage
                      src="../../../multimedia/placeholder-image.png"
                      alt="Placeholder Image"
                    />
                  )}
                </MobileEventFrame>
              </Link>
            ))}
          </StyledSlider>
        </Col>
      </StyledRow>
      <StyledRow className="centered">
        <StyledButton to="/id/privacy-policy/" style={{ marginTop: 50 }}>
          <Paragraph18>Lihat Kebijakan Pribadi</Paragraph18>
        </StyledButton>
      </StyledRow>
      <CircleBg className="circle-bg" />
    </HomeContainer>
  )
}

export default HomeContentId

const HomeContainer = styled(Container)`
  position: relative;
  margin-top: 100px;
  padding-bottom: 100px;
  background: #f7f8fc;
  overflow: hidden;
  z-index: 1;

  #project-highlights {
    padding-bottom: 50px;
  }

  .centered {
    justify-content: center;
    align-items: center;
  }

  .with-padding {
    padding: 50px 10%;

    @media only screen and (max-width: 768px) {
      padding: 12px 5%;
    }
  }

  .circle-bg {
    position: absolute;
    bottom: -696px;
    left: -696px;
    z-index: -1;
  }

  .white-bg {
    background: #ffffff;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
  }

  h1 {
    text-align: center;
    margin-bottom: 25px;
  }

  .news-events-section {
    h1 {
      text-align: left;
    }

    p {
      font-weight: bold;
    }

    .news-date {
      min-width: 20%;

      @media only screen and (max-width: 1680px) and (min-width: 1367px) {
        min-width: 30%;
      }

      @media only screen and (max-width: 1367px) and (min-width: 1024px) {
        min-width: 25%;
      }

      @media only screen and (max-width: 1024px) and (min-width: 768px) {
        min-width: 0;
      }
    }

    .news-title {
      @media only screen and (max-width: 769px) {
        width: 100%;
      }
    }
  }

  .mobile-events-slider {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
`

const StyledRow = styled(Row)`
  display: flex;
  position: relative;
  z-index: 0;
  padding-top: 25px;

  @media only screen and (max-width: 1025px) {
    .col-recent-news,
    .col-events {
      width: 100%;
    }
  }

  .col-events {
    @media only screen and (max-width: 1024px) and (min-width: 1px) {
      margin-top: 50px;
    }
  }

  .recent-news {
    margin-bottom: 25px;

    @media only screen and (min-width: 1023px) {
      min-height: 350px;
    }
  }

  .jfeei-offices-map {
    display: flex;
    justify-content: center;
    padding: 0;
    overflow: hidden;

    @media only screen and (max-width: 1367px) {
      height: 500px;
    }

    @media only screen and (max-width: 769px) {
      height: 400px;
    }

    @media only screen and (max-width: 500px) {
      height: 270px;
    }

    @media only screen and (max-width: 376px) {
      height: 200px;
    }
  }

  .indonesia-id {
    .title {
      color: #0073af;
    }
  }

  .hq-id {
    .title {
      color: #aac532;
    }
  }

  .title {
    @media only screen and (max-width: 1024px) {
      padding-left: 25px;
    }

    @media only screen and (max-width: 500px) {
      padding-left: 0;
      padding-top: 10px;
    }
  }

  .project-title {
    @media only screen and (max-width: 500px) {
      padding: 10px;
    }
  }

  .news {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    margin-bottom: 15px;

    @media only screen and (min-width: 1024px) and (max-width: 1367px) {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .jfeei {
    h1 {
      color: #0073af;
    }

    p {
      color: #149be1;
    }
  }

  .jfee {
    h1,
    p {
      color: #aac532;
    }

    svg {
      fill: #aac532;
      margin-bottom: 5px;
    }
  }

  .news-link {
    display: flex;
    margin-bottom: 25px;

    svg {
      margin-right: 10px;
    }

    h1 {
      text-align: left;
      margin: 0;

      @media only screen and (max-width: 500px) {
        line-height: 25px;
      }
    }

    p {
      text-align: left;
    }
  }
`

const EventImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .event-images-container {
    display: flex;
    position: relative;
    width: 70%;

    h1 {
      font-size: 20px; !important;

      @media only screen and (max-width: 1367px) {
        font-size: 16px; !important;
      }
    }

    @media only screen and (min-width: 1025px) {
      width: 100%;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .featured-event-1 {
    position: absolute;
    right: 320px;
    transform: rotate(-10deg);
    z-index: 1;
  }

  .featured-event-2 {
    position: absolute;
    top: 0;
    right: 30px;
    transform: rotate(10deg);
    z-index: 0;
  }

  .featured-event-3 {
    position: absolute;
    top: 145px;
    right: 175px;
    transform: rotate(0);
    z-index: 2;
  }

  .featured-event-4 {
    position: absolute;
    top: 320px;
    right: 320px;
    transform: rotate(-10deg);
    z-index: 0;
  }

  .featured-event-5 {
    position: absolute;
    top: 320px;
    right: 30px;
    transform: rotate(10deg);
    z-index: 1;
  }

  @media only screen and (max-width: 1025px) and (min-width: 900px) {
    height: 500px;

    .featured-event-1 {
      left: 45px;
    }

    .featured-event-2 {
      top: 0;
      left: 270px;
    }

    .featured-event-3 {
      top: 115px;
      left: 160px;
    }

    .featured-event-4 {
      top: 250px;
      left: 45px;
    }

    .featured-event-5 {
      top: 250px;
      left: 275px;
    }
  }

  @media only screen and (width: 1025px) {
    height: 500px;
    .featured-event-1 {
      top: 50px;
      left: 150px;
    }

    .featured-event-2 {
      top: 50px;
      left: 390px;
    }

    .featured-event-3 {
      top: 165px;
      left: 270px;
    }

    .featured-event-4 {
      top: 300px;
      left: 150px;
    }

    .featured-event-5 {
      top: 300px;
      left: 390px;
    }
  }

  @media only screen and (max-width: 1550px) and (min-width: 1025px) {
    height: 500px;
    .featured-event-1 {
      top: 0;
      left: 5%;
    }

    .featured-event-2 {
      top: 0;
      left: 50%;
    }

    .featured-event-3 {
      top: 115px;
      left: 25%;
    }

    .featured-event-4 {
      top: 250px;
      left: 5%;
    }

    .featured-event-5 {
      top: 250px;
      left: 50%;
    }
  }

  @media only screen and (max-width: 900px) and (min-width: 768px) {
    height: 500px;
    .featured-event-1 {
      top: 50px;
      left: 0;
    }

    .featured-event-2 {
      top: 50px;
      left: 50%;
    }

    .featured-event-3 {
      top: 165px;
      left: 25%;
    }

    .featured-event-4 {
      top: 300px;
      left: 0;
    }

    .featured-event-5 {
      top: 300px;
      left: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

const EventTitle = styled.div`
  height: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;

  h1 {
    text-align: center !important;
  }
`

const EventFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 225px;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.3);
  padding: 5px;
  transition: 0.3s ease-in-out;

  h1 {
    margin-bottom: 5px;
  }

  &:hover {
    z-index: 3;
    transform: rotate(0);
    transform: scale(1.5, 1.5);
    transition: 0.5s ease-in-out;

    ${EventTitle} {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }
  }

  @media only screen and (max-width: 1550px) {
    width: 250px;
    height: 192.5px;

    &:hover {
      transform: scale(1.5, 1.5);

      ${EventTitle} {
        height: 50px;
      }
    }
  }
`

const MobileEventFrame = styled.div`
  background: #ffffff;
  padding: 10px;
  margin: 0 10px;

  h1 {
    margin-bottom: 10px;
    text-align: center !important;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const StyledCol = styled(Col)`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .heading {
    padding: 10px;
    background: linear-gradient(180deg, #149be1 27.08%, #0073af 161.84%);
    border-radius: 20px;
    min-height: 160px;

    h1 {
      font-weight: bold;
      font-size: 88px;
      color: #ffffff;
      margin-bottom: 45px;

      @media only screen and (max-width: 769px) {
        font-size: 44px;
      }
    }

    @media only screen and (max-width: 1367px) {
      h1 {
        font-size: 68px;
      }
      min-height: 120px;
    }

    @media only screen and (max-width: 769px) {
      font-size: 44px;
      height: 120px;
      min-height: 120px;
    }
  }

  .body {
    transform: translateY(-10%);
    min-height: 335px;
    height: 400px;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 10px 10px;
    background: #fff;
    padding: 30px;

    .description {
      color: #0073af;
      text-align: center;
      margin-bottom: 25px;
    }

    .date {
      color: #8f9496;
      width: 100%;
      text-align: left;
      margin-top: 25px;
    }

    @media only screen and (max-width: 1367px) {
      height: 0;
    }
  }

  h1 {
    color: #00a5df;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
`

const StyledSlider = styled(Slider)`
  padding: 0;

  .item-container {
    margin-bottom: 25px;
  }

  .item-container img {
    height: 450px;

    @media only screen and (max-width: 1367px) and (min-width: 1099px) {
      height: 325px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1099px) {
      height: 500px;
    }

    @media only screen and (max-width: 768px) {
      height: 50vw;
    }
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  .slick-track {
    display: flex;
  }

  @media only screen and (min-width: 1388px) {
    .slick-track {
      height: 100%;
    }
  }
`

const CarouselItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;

  .carousel-img {
    width: 100%;
    height: 100%;
  }

  p {
    color: #ffffff;
    padding: 15px 30px;
    margin: 0 !important;
    white-space: normal;
  }

  .carousel-item-title {
    width: 100%;
    padding: inherit;
    position: absolute;
    bottom: 0;
    left: 0;

    .heading {
      overflow: hidden;
      background: linear-gradient(
        180deg,
        rgba(0, 115, 175, 0.9) 0%,
        rgba(20, 155, 225, 0.9) 0.01%,
        rgba(0, 115, 175, 0.9) 141.23%
      );

      p {
        font-weight: bold;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 5%;
  }

  @media only screen and (max-width: 500px) {
    p {
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
    }
  }
`
